<template>
  <div class="relative py-16 bg-white">
    <div
      class="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
      aria-hidden="true"
    ></div>
    <div class="max-w-7xl mx-auto bg-white lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="relative z-9 lg:col-start-2 lg:row-start-1 lg:col-span-6 lg:bg-transparent"
        >
          <div
            class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
            aria-hidden="true"
          ></div>
          <div
            class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0"
          >
            <div
              class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-3 lg:aspect-h-2"
            >
              <vue-flip active-hover width="100%" height="100%">
                <template v-slot:front>
                  <img
                    class="object-fill object-center shadow-2xl"
                    :src="recruiters[$route.params.id].mainPhoto"
                    :alt="recruiters[$route.params.id].nickname"
                  />
                </template>
                <template v-slot:back>
                  <img
                    class="object-fill object-center shadow-2xl"
                    :src="recruiters[$route.params.id].secondaryPhoto"
                    :alt="recruiters[$route.params.id].nickname"
                  />
                </template>
              </vue-flip>
            </div>
          </div>
        </div>

        <div
          class="relative bg-white lg:col-start-7 lg:row-start-1 lg:my-8 lg:col-span-5 lg:grid lg:grid-cols-12 lg:items-center"
        >
          <div
            class="relative max-w-md mx-auto py-24 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-2 lg:col-span-7 text-left"
          >
            <h2 class="text-2xl font-medium text-black" id="join-heading">
              {{ recruiters[$route.params.id].name }}
            </h2>
            <p
              class="text-lg text-black"
              v-html="recruiters[$route.params.id].description"
            ></p>
            <p class="text-lg text-black">
              Follow her at:
              <a
                :href="recruiters[$route.params.id].linkedInUrl"
                class="m-5 text-blue-400 hover:text-blue-500"
                target="_blank"
              >
                <span class="">{{ recruiters[$route.params.id].name }}</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:my-12"
    >
      <router-link
        class="block w-full py-3 px-12 text-center bg-black border border-transparent shadow-md text-base font-medium text-white hover:bg-gray-700 sm:inline-block sm:w-auto"
        to="/recruiters"
        >See all recruiters</router-link
      >
    </div>
  </div>
</template>

<script>
import VueFlip from "vue-flip";

export default {
  name: "SingleRecruiter",
  components: {
    "vue-flip": VueFlip,
  },
  params: {
    id: Number,
  },
  data: function() {
    return {
      recruiters: {
        1: {
          id: 1,
          name: "MARY CORNEJO",
          nickname: "Mary",
          mainPhoto: "/images/Mary1.png",
          secondaryPhoto: "/images/Mary2.png",
          description:
            "Making deals and working with people are her passion. She stands out in the recruitment industry as she has been working on it for 14 years, of which 9 has been in IT. <br />She consider herself as an experienced woman dealing with people, working with teams, doing and closing deals. She always establishes good relations with candidates, clients, managers and all the areas involved.",
          linkedInUrl: "https://www.linkedin.com/in/mary-cornejo-57959b77/",
        },
        2: {
          id: 2,
          name: "FRAN VARELA",
          nickname: "Fran",
          mainPhoto: "/images/Fran1.png",
          secondaryPhoto: "/images/Fran2.png",
          description:
            "Graduated in Business Administration with more than 10 years of experience in HR, always within companies dedicated to Technology. Her specialty is recruitment in IT profiles, from management and senior profiles, developers and analysts, also consulting profiles, testers, project managers, IT commercial managers, telecommunications engineers and server administration, among others. <br />IT Talent Attraction Consultant at ScreenIT. Experienced recruiter and dancer by vocation. Lover of salsa, movies, swimming and good meat and wine.",
          linkedInUrl: "https://www.linkedin.com/in/fransueliv/",
        },
        /*3: {
          id: 3,
          name: "VERO CRUZ",
          nickname: "Vero",
          mainPhoto: "/images/Vero1.jpg",
          secondaryPhoto: "/images/Vero2.jpg",
          description:
            "Psychologist with 8 years of experience in recruitment and selection, specialized in recruiting IT profiles. She has worked for banking, information security and consulting companies with operations in Mexico, Argentina, Brazil and the United States. Focused on managing and attending to the complete hiring cycle, matching talent with offers that are the perfect match for both. <br />She loves being active, running, mountain biking and venturing out to try new experiences.",
          linkedInUrl: "https://www.linkedin.com/in/veronica-cruz-44a98693/",
        },
        4: {
          id: 4,
          name: "CARMEN LUNA",
          nickname: "Car",
          mainPhoto: "/images/Carmen1.jpg",
          secondaryPhoto: "/images/Carmen2.jpg",
          description:
            "She’s a bilingual IT Recruiter and Headhunter with +3 years of experience in IT sectors and 1 year at engineering field. She has experience working with multicultural teams and recruiting talent for different countries: USA, Colombia, Peru, Chile, Argentina, Panama, Dominican Republic and Mexico. <br />The profiles she has been looking for are: Front End, Back End and Fullstack Developers, DevOps, Salesforce Consultant, Software Architect, UX / UI Designers, QA Testers, Database Analyst, Head IT, Network Consultant, Monitoring Consultant, IT Recruiters, Technical Lead, Mobile Developers, Product Manager, SDI, Administrative profiles, PM, PO, Technical Support L1, L2, L3, etc. <br />She loves to learn about other cultures, meeting people from other countries and I want to continue learning about the tech world.",
          linkedInUrl: "https://www.linkedin.com/in/carmenlunac/",
        },*/
        5: {
          id: 5,
          name: "YANELI PACHECO",
          nickname: "Yane",
          mainPhoto: "/images/Yane1.png",
          secondaryPhoto: "/images/Yane2.png",
          description:
            "Yaneli studied administration and then worked psychology. <br />She has been working for 6 years in HR area, from recruitment and selection of personnel, administrative control, training and labor relations. <br />She has been recruiting profiles for technology area for 5 years within the banking, financial, retail, commercial, automotive, startup industries. The profiles she has recruited are: Net, Java, C #, Tester, QA, Cobol, Services Desk, Scrum Master, PHP, Front End and Back End, among others. <br />She likes sour sweets and dinosaurs (Rawr!).",
          linkedInUrl: "https://www.linkedin.com/in/yaneli-pacheco89/",
        },
        6: {
          id: 6,
          name: "LUZ VISTRAIN",
          nickname: "Luz",
          mainPhoto: "/images/Luz1.png",
          secondaryPhoto: "/images/Luz2.png",
          description:
            "Psychologist with more than 8 experience focused on attracting and selecting talent in IT. She likes dogs and enjoy nature.",
          linkedInUrl:
            "https://www.linkedin.com/in/luz-vistra%C3%ADn-cuenca-29454525/",
        },
        7: {
          id: 7,
          name: "EDGAR NEGRETE",
          nickname: "Edgar",
          mainPhoto: "/images/Edgar1.png",
          secondaryPhoto: "/images/Edgar2.png",
          description:
            "Industrial Psychologist, IT recruitment specialist, headhunter, English teacher, Recruitment Leader, analyst, and formats and processes consultant with 10 years of experience. I’m into the whole analysis and improvement of the recruitment process; passionate about technology, cat lover, digital entertainment and proud member LGBTTTQIA + specialized in IT recruitment with 10 years of experience, serving international and national clients in various modalities.",
          linkedInUrl:
            "https://www.linkedin.com/in/edgarnegrete/",
        },
        8: {
          id: 8,
          name: "JUAN EDUARDO ORTEGA",
          nickname: "Lalo",
          mainPhoto: "/images/Lalo1.png",
          secondaryPhoto: "/images/Lalo2.png",
          description:
            "",
          linkedInUrl:
            "https://www.linkedin.com/in/juan-eduardo-ortega-carrasco-5393a7164/",
        },
      },
    };
  },
};
</script>

<style scoped></style>
