<template>
  <div class="bg-white">
    <main>
      <single-recruiter></single-recruiter>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import SingleRecruiter from '../components/recruiters/SingleRecruiter.vue';

export default {
  name: 'Recruiter',
  components: {
    SingleRecruiter,
  }
}
</script>
