import { render, staticRenderFns } from "./SingleRecruiter.vue?vue&type=template&id=361a4025&scoped=true&"
import script from "./SingleRecruiter.vue?vue&type=script&lang=js&"
export * from "./SingleRecruiter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361a4025",
  null
  
)

export default component.exports